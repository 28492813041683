import React from "react"

import "./Hitmarker.css"

function Hitmarker() {

    // const createCursor = (x,y) => {
    //     const cursor = document.createElement('div');
    //     cursor.className = 'cursor';
    //     cursor.style.left = x + 'px';
    //     cursor.style.top = y + 'px';
    //     return cursor;
    // }

    // const removerCursor = cursor => {
    //     const timeout = setTimeout(() => {
    //         cursor.remove();
    //         clearTimeout(timeout);
    //     }, 1000)
    // }

    // window.addEventListener('click', e => {
    //     const cursor = createCursor(e.pageX, e.pageY);
    //     document.body.appendChild(cursor);
    //     removerCursor(cursor);
    //     console.log(e.pageX, e.pageY);
    // })
        
    return (
        <>
        </>
    )
}

export default Hitmarker